import { type DefaultValue, type SubmissionResult, useForm as useConform } from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import { useActionData, useFetcher, useNavigation, useSubmit } from '@remix-run/react'
import { useCallback, useEffect } from 'react'
import { z } from 'zod'

const IntentSchema = z.string()

type UseConformProps<T extends Record<string, any>> = Parameters<typeof useConform<T>>[0]

type UseFormProps<Schema extends z.ZodObject<any>> = {
  schema: Schema
  useFetcher?: boolean
  fetcherKey?: string
  onSuccess?: () => void
} & UseConformProps<z.infer<Schema>>

export function useForm<Schema extends z.ZodObject<any>>({
  schema,
  useFetcher: submitWithFetcher = true,
  fetcherKey: explicitFetcherKey,
  shouldValidate = 'onBlur',
  onSuccess = () => {},
  defaultValue,
  ...props
}: UseFormProps<Schema>) {
  const navigation = useNavigation()
  const actionData = useActionData()
  const submit = useSubmit()
  const intent = IntentSchema.parse(schema.shape.intent.value)
  const fetcherKey = explicitFetcherKey || intent
  const fetcher = useFetcher({ key: fetcherKey })

  const data = submitWithFetcher ? fetcher.data : actionData
  const formData = submitWithFetcher ? fetcher.formData : navigation.formData
  const state = submitWithFetcher ? fetcher.state : navigation.state

  const isSubmitting = state !== 'idle' && formData?.get('intent') === intent

  const lastResult = hasSubmissionResult(data) ? data.submission : null

  const [form, fields] = useConform<z.infer<Schema>>({
    ...props,
    lastResult: !isSubmitting ? lastResult : null,
    shouldValidate,
    defaultValue: {
      ...((defaultValue ?? {}) as Partial<z.infer<Schema>>),
      intent,
      fetcherKey
    } as DefaultValue<z.TypeOf<Schema>>,
    constraint: getZodConstraint(schema),
    onValidate({ formData }) {
      const result = parseWithZod(formData, { schema })
      if (result.status === 'error') {
        console.warn(result.error)
      }
      return result
    },
    onSubmit(e, context) {
      e.preventDefault()
      const submitFn = submitWithFetcher ? fetcher.submit : submit
      submitFn(context.formData, {
        method: context.method,
        action: context.action,
        encType: context.encType
      })
    }
  })

  const onSuccessCallback = useCallback(onSuccess, [])

  const { status } = form

  useEffect(() => {
    if (status === 'success') {
      onSuccessCallback()
    }
  }, [status, onSuccessCallback])

  return [form, fields] as const
}

export function hasSubmissionResult(value: unknown): value is { submission: SubmissionResult } {
  if (!value || typeof value !== 'object') return false

  const maybeSubmission = (value as any).submission

  if (!maybeSubmission || typeof maybeSubmission !== 'object') return false

  const hasValidTypes = Object.entries(maybeSubmission).every(([key, value]) => {
    switch (key) {
      case 'status':
        return value === undefined || value === 'error' || value === 'success'
      case 'intent':
      case 'state':
        return value === undefined || typeof value === 'string'
      case 'initialValue':
        return value === undefined || value === null || typeof value === 'object'
      case 'fields':
        return value === undefined || Array.isArray(value)
      case 'error':
        return value === undefined || value === null || typeof value === 'object'
      default:
        return false
    }
  })

  return hasValidTypes
}
