import { type FieldName, unstable_useControl as useControl, useField } from '@conform-to/react'
import { Checkbox as UICheckbox } from '@repo/ui/components/Checkbox.js'
import { type ComponentProps, type ElementRef, useRef } from 'react'
import { useFieldName } from './Field'

export function Checkbox({ ...props }: ComponentProps<typeof UICheckbox>) {
  const name = useFieldName() as FieldName<string | boolean | undefined>
  const [meta] = useField(name)
  const checkboxRef = useRef<ElementRef<typeof Checkbox>>(null)
  const control = useControl(meta)

  return (
    <>
      <input
        className="sr-only"
        aria-hidden
        ref={control.register}
        name={meta.name}
        tabIndex={-1}
        defaultValue={meta.initialValue}
        onFocus={() => checkboxRef.current?.focus()}
      />
      <UICheckbox
        {...props}
        ref={checkboxRef}
        id={meta.id}
        checked={control.value === 'on'}
        onCheckedChange={(checked) => {
          control.change(checked ? 'on' : '')
        }}
        onBlur={control.blur}
      />
    </>
  )
}
