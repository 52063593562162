import { type FormMetadata, FormProvider, getFormProps, useFormMetadata } from '@conform-to/react'
import { Form as RemixForm } from '@remix-run/react'
import { ErrorMessage } from '@repo/ui/components/ErrorMessage.js'
import type { ComponentProps, PropsWithChildren } from 'react'
import { z } from 'zod'
import { Checkbox } from './Checkbox'
import { Field, FieldErrors, FieldHint, Label } from './Field'
import { FieldList } from './FieldList'
import { HiddenInput, Input, Select, Textarea } from './Inputs'
import { MultiSelect } from './MultiSelect'
import { SubmitButton } from './SubmitButton'
import { ToggleGroup } from './ToggleGroup'

type FormProps = { form: FormMetadata<any> } & ComponentProps<typeof RemixForm>

const FormInitialValueSchema = z
  .object({ intent: z.string(), fetcherKey: z.string() })
  .passthrough()

export const useInitialValues = (form: FormMetadata<any>) => {
  return FormInitialValueSchema.parse(form.initialValue)
}

const Form = ({
  children,
  method = 'POST',
  action,
  form,
  key,
  ...props
}: PropsWithChildren<FormProps>) => {
  const { intent } = useInitialValues(form)

  return (
    <FormProvider context={form.context}>
      <RemixForm key={key} {...getFormProps(form)} method={method} action={action} {...props}>
        <input type="hidden" name="intent" value={intent} />
        {children}
        <FormErrors className="mt-2" />
      </RemixForm>
    </FormProvider>
  )
}

const FormErrors = ({ ...props }: ComponentProps<'div'>) => {
  const form = useFormMetadata()
  if (!form.errors?.some(Boolean)) return null

  return (
    <div className="space-y-1" {...props}>
      {form.errors.map((error, i) => {
        return <ErrorMessage key={String(i)}>{error}</ErrorMessage>
      })}
    </div>
  )
}

Form.Field = Field
Form.Label = Label
Form.FieldErrors = FieldErrors
Form.Input = Input
Form.Textarea = Textarea
Form.HiddenInput = HiddenInput
Form.Select = Select
Form.Checkbox = Checkbox
Form.MultiSelect = MultiSelect
Form.FieldHint = FieldHint
Form.FieldList = FieldList
Form.ToggleGroup = ToggleGroup
Form.SubmitButton = SubmitButton

export { Form }
