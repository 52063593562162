import { type FieldName, useField, useInputControl } from '@conform-to/react'
import { MultiSelect as UIMultiSelect } from '@repo/ui/components/MultiSelect'
import { type PropsWithChildren, useMemo } from 'react'
import { useFieldName } from './Field'

type MultiSelectOption = {
  value: string
  label: string
}

type MultiSelectProps<T extends MultiSelectOption> = {
  options: T[]
  onSearch?: (query: string) => void
  onSelect?: (value: T['value']) => void
  renderOption?: (option: T) => React.ReactNode
  renderSelectedOption?: (props: {
    option: T
    onRemove: () => void
  }) => React.ReactNode
}

const MultiSelect = <T extends MultiSelectOption>({
  options,
  children,
  onSearch,
  renderOption,
  renderSelectedOption
}: PropsWithChildren<MultiSelectProps<T>>) => {
  const fieldName = useFieldName() as FieldName<string[] | number[]>
  const [meta] = useField(fieldName)
  const control = useInputControl(meta)

  if (typeof control.value === 'string') throw new Error('MultiSelect value must be an array')

  const handleSelect = (value: T['value'], isSelected: boolean) => {
    if (typeof control.value === 'string') throw new Error('MultiSelect value must be an array')
    if (isSelected) {
      return control.change([...(control.value || []), value])
    }
    return control.change(control.value?.filter((v) => v !== value))
  }

  const selectedOptions = useMemo(
    () => options.filter((option) => control.value?.includes(option.value)),
    [control.value, options]
  )

  return (
    <UIMultiSelect
      options={options}
      selectedOptions={selectedOptions}
      onSearch={onSearch}
      onSelect={handleSelect}
      renderOption={renderOption}
      renderSelectedOption={renderSelectedOption}
    >
      {children}
    </UIMultiSelect>
  )
}

MultiSelect.Trigger = UIMultiSelect.Trigger
MultiSelect.TriggerPlaceholder = UIMultiSelect.TriggerPlaceholder
MultiSelect.Content = UIMultiSelect.Content
MultiSelect.Options = UIMultiSelect.Options
MultiSelect.Search = UIMultiSelect.Search
MultiSelect.Empty = UIMultiSelect.Empty
MultiSelect.SelectedOptions = UIMultiSelect.SelectedOptions

export { MultiSelect }
