import {
  type ComponentProps,
  Fragment,
  type PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState
} from 'react'
import { cn } from '#utils/utils.js'
import { Badge } from './Badge'
import { Button } from './Button'
import { Command, CommandInput, CommandItem, CommandList } from './Command'
import { Popover, PopoverContent, PopoverTrigger } from './Popover'

type MultiSelectOption = {
  value: string | number
  label: string
}

type MultiSelectProps<T extends MultiSelectOption> = {
  options: T[]
  selectedOptions: T[]
  onSearch?: (query: string) => void
  onSelect: (value: T['value'], isSelected: boolean) => void
  renderOption?: (option: T) => React.ReactNode
  renderSelectedOption?: (props: {
    option: T
    onRemove: () => void
  }) => React.ReactNode
}

type MultiSelectContextType<T extends MultiSelectOption> = {
  options: T[]
  selectedOptions: T[]
  onSearch: MultiSelectProps<T>['onSearch']
  query: string
  onSelect: MultiSelectProps<T>['onSelect']
  setQuery: React.Dispatch<React.SetStateAction<string>>
  renderOption?: MultiSelectProps<T>['renderOption']
  renderSelectedOption?: MultiSelectProps<T>['renderSelectedOption']
}

const MultiSelectContext = createContext<MultiSelectContextType<any> | undefined>(undefined)

const useMultiSelect = <T extends MultiSelectOption>() => {
  const context = useContext(MultiSelectContext) as MultiSelectContextType<T> | undefined
  if (!context) throw new Error('useMultiSelect must be used within a <MultiSelect />')
  return context
}

const MultiSelect = <T extends MultiSelectOption>({
  options,
  selectedOptions,
  children,
  onSearch,
  onSelect,
  renderOption,
  renderSelectedOption
}: PropsWithChildren<MultiSelectProps<T>>) => {
  const [query, setQuery] = useState('')

  return (
    <MultiSelectContext.Provider
      value={{
        options,
        selectedOptions,
        renderOption,
        renderSelectedOption,
        onSelect,
        onSearch,
        query,
        setQuery
      }}
    >
      <Popover>{children}</Popover>
    </MultiSelectContext.Provider>
  )
}

const Trigger = ({ className, variant = 'outline', ...props }: ComponentProps<typeof Button>) => {
  return (
    <PopoverTrigger asChild>
      <Button
        {...props}
        variant={variant}
        className={cn('w-full justify-start h-fit py-1.5 min-h-10', className)}
      />
    </PopoverTrigger>
  )
}

const TriggerPlaceholder = ({ className, ...props }: ComponentProps<'div'>) => {
  const { selectedOptions } = useMultiSelect()

  if (selectedOptions.length) return null

  return <div className={cn(className)} {...props} />
}

const SelectedOptions = ({ className, children, ...props }: ComponentProps<'div'>) => {
  const { selectedOptions, renderSelectedOption, onSelect } = useMultiSelect()

  if (!selectedOptions.length) return null

  return (
    <div className={cn('flex items-center gap-1 flex-wrap', className)} {...props}>
      {selectedOptions.map((option) => {
        if (renderSelectedOption) {
          return (
            <Fragment key={option.value}>
              {renderSelectedOption({
                option,
                onRemove: () => onSelect(option.value, false)
              })}
            </Fragment>
          )
        }

        return (
          <Badge key={option.value} variant="secondary" className="flex items-center gap-2">
            {option.label}
          </Badge>
        )
      })}
    </div>
  )
}

const Content = ({ className, children, ...props }: ComponentProps<typeof PopoverContent>) => {
  const { onSearch } = useMultiSelect()
  return (
    <PopoverContent align="start" className={cn('p-1', className)} {...props}>
      <Command shouldFilter={!onSearch}>{children}</Command>
    </PopoverContent>
  )
}

const Search = ({
  className,
  placeholder = 'Search options...',
  ...props
}: ComponentProps<typeof CommandInput>) => {
  const { query, setQuery, onSearch } = useMultiSelect()
  return (
    <CommandInput
      className={cn('w-full', className)}
      value={query}
      onValueChange={(query) => {
        setQuery(query)
        onSearch?.(query)
      }}
      placeholder={placeholder}
      {...props}
    />
  )
}

const Options = () => {
  const { options, selectedOptions, renderOption, setQuery, onSelect } = useMultiSelect()

  const unselectedOptions = useMemo(
    () => options.filter((option) => !selectedOptions.includes(option)),
    [options, selectedOptions]
  )

  if (!unselectedOptions.length) return null

  return (
    <CommandList>
      {unselectedOptions.map((option) => {
        return (
          <CommandItem
            key={option.value}
            value={option.label}
            onSelect={() => {
              setQuery('')
              onSelect(option.value, true)
            }}
          >
            {renderOption ? renderOption(option) : option.label}
          </CommandItem>
        )
      })}
    </CommandList>
  )
}

const Empty = ({ className, ...props }: ComponentProps<'div'>) => {
  const { options } = useMultiSelect()

  if (options.length) return null

  return <div className={cn('text-center py-2 text-muted-foreground', className)} {...props} />
}

MultiSelect.Trigger = Trigger
MultiSelect.TriggerPlaceholder = TriggerPlaceholder
MultiSelect.Content = Content
MultiSelect.Options = Options
MultiSelect.Search = Search
MultiSelect.Empty = Empty
MultiSelect.SelectedOptions = SelectedOptions

export { MultiSelect }
