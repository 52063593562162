import type { ComponentProps } from 'react'
import { cn } from '#utils/utils.js'

export const ErrorMessage = ({ className, ...props }: ComponentProps<'span'>) => {
  return (
    <span
      className={cn(
        'text-destructive bg-rose-100 py-1 px-2 rounded-sm text-xs font-medium w-fit block',
        className
      )}
      {...props}
    />
  )
}
