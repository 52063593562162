import { getInputProps, getTextareaProps, useField } from '@conform-to/react'
import { useInputControl } from '@conform-to/react'
import type { FieldName } from '@conform-to/react'
import { Input as UIInput } from '@repo/ui/components/Input.js'
import { Select as UISelect } from '@repo/ui/components/Select.js'
import { Textarea as UITextarea } from '@repo/ui/components/Textarea.js'
import type { ComponentProps } from 'react'
import { useFieldName } from './Field'

export const Input = ({
  type = 'text',
  key,
  ...props
}: ComponentProps<typeof UIInput> & { type?: Parameters<typeof getInputProps>[1]['type'] }) => {
  const name = useFieldName() as FieldName<string | number>
  const [meta] = useField(name)

  return (
    <UIInput
      {...getInputProps(meta, { type, ariaAttributes: true })}
      key={key}
      autoComplete="off"
      {...props}
    />
  )
}

export const Textarea = ({ key, ...props }: ComponentProps<typeof UITextarea>) => {
  const name = useFieldName() as FieldName<string>
  const [meta] = useField(name)
  return <UITextarea {...getTextareaProps(meta)} key={key} {...props} />
}

export const Select = ({ ...props }: ComponentProps<typeof UISelect>) => {
  const name = useFieldName() as FieldName<string | number>
  const [meta] = useField(name)
  const control = useInputControl(meta)

  if (Array.isArray(control.value)) throw new Error('Select value must be a string or number')

  return (
    <UISelect
      {...props}
      value={control.value ?? ''}
      onValueChange={control.change}
      onOpenChange={(open) => {
        if (!open) {
          control.blur()
        }
      }}
    />
  )
}

export const HiddenInput = ({
  name,
  key,
  ...props
}: ComponentProps<'input'> & { name: FieldName<any> }) => {
  const [meta] = useField(name)
  return <input {...getInputProps(meta, { type: 'hidden' })} key={key} {...props} />
}
